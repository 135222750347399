<script setup lang="ts">
import { type Notification } from '../types';
import { BellIcon } from '@glow/shared/components/icons';
import { getNotificationText } from '../texts';
import { getNotificationCategoryTitle } from '../categories';

const props = defineProps<{
  notification: Notification;
}>();
</script>

<template>
  <div
    :class="['notification', { notification_read: props.notification.isRead }]"
  >
    <div class="d-flex justify-content-center align-items-center">
      <BellIcon class="notification__icon" />
    </div>
    <div class="d-flex flex-column gap-1">
      <div class="notification__category">
        {{ getNotificationCategoryTitle(props.notification.category) }}
      </div>
      <div class="notification__text">
        {{ getNotificationText(props.notification) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@glow/shared/assets/core.scss';

.notification {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 24px;
  background: #f1f1ff;
  border-radius: 16px;

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &_read {
    background: #f7f7f8;
  }

  &__icon {
    width: 48px;
    height: 48px;
    color: var(--app-color-primary);

    @include media-breakpoint-down(sm) {
      width: 32px;
      height: 32px;
    }
  }

  &__category {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  &__text {
    font-size: 15px;
    line-height: 17px;
  }
}
</style>
