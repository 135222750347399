import type { Notification } from './types';

export function getNotificationText(notification: Notification) {
  const { type, data } = notification;
  switch (type) {
    case 'role-assign':
      return `Вам присвоена роль ${data.role}`;
    case 'role-unassign':
      return `Вы были сняты с роли ${data.role}`;
    case 'company-join':
      return `В компанию ${data.company} присоединился мастер ${data.master}`;
    case 'company-leave':
      return `Мастер ${data.master} покинул компанию ${data.company}`;
    case 'company-dismiss':
      return `Вы были исключены из компании ${data.company}`;
    case 'company-invite':
      return `Вас пригласили в компанию ${data.company}`;
    case 'appointment-cancel':
      return `Запись ${data.dateTime} на услуги ${data.services.join(
        ', '
      )} была отменена`;
    case 'appointment-master-review':
      return `Клиент ${data.client} оставил отзыв с рейтингом ${data.rating} к записи ${data.dateTime}`;
    case 'appointment-company-review':
      return `Клиент ${data.client} оставил отзыв компании с рейтингом ${data.rating} к записи ${data.dateTime}`;
    case 'company-application-approve':
      return `Заявка на создание компании ${data.company} была одобрена`;
    case 'company-application-decline':
      return `Заявка на создание компании ${data.company} была отклонена по причине: ${data.reason}`;
    case 'appointment-create':
      return `Клиент ${data.client} записался на услуги ${data.services.join(
        ', '
      )} на ${data.dateTime}`;
  }
}
