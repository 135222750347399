<script setup lang="ts">
import {
  CatalogIcon,
  CloseIcon,
  NearIcon,
  BellIcon,
  LogoHorizontalIcon,
  UserIcon,
} from '@glow/shared/components/icons';
import { NotificationModal } from '@/features/notifications';
import { useAuthModalStore } from '@glow/shared/features/auth';
import AddressSelectModal from './AddressSelectModal.vue';
import { graphql } from '@/gql';
import { useQuery } from '@urql/vue';
import AppLayoutCatalogMenu from './AppLayoutCatalogMenu.vue';
import AppLayoutSearchBar from './AppLayoutSearchBar.vue';
import { useAddressStore } from '@/stores/address';
import AppLayoutProfilePopover from './AppLayoutProfilePopover.vue';

const { showAuthModal } = useAuthModalStore();
const isLoggedIn = useIsLoggedIn();
const userId = useUserId();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query AppLayoutHeader_UserQuery($id: Int!) {
      user(id: $id) {
        id
        avatarUrl

        ...AppLayoutProfilePopover_UserFragment
      }
    }
  `),
  variables: toRef({ id: userId.value! }),
  pause: toRef(() => !userId.value),
});
const user = toRef(() => queryData.value?.user);
const isCatalogMenuVisible = ref(false);

const isNotificationModalOpened = ref(false);
const isAddressSelectModalOpened = ref(false);
const isProfilePopoverVisible = ref(false);
const addressStore = useAddressStore();
</script>

<template>
  <div>
    <div>
      <ClientOnly>
        <AppButton
          fluid
          class="d-block d-lg-none p-1"
          border-radius="none"
          compact
          color="light"
          text-align="center"
          icon-align="center"
          :icon-start="NearIcon"
          @click="isAddressSelectModalOpened = true"
        >
          <span
            class="header__address"
            :class="{ 'text-secondary': !addressStore.address }"
          >
            {{ addressStore.address ?? 'Укажите ваш адрес' }}
          </span>
        </AppButton>
      </ClientOnly>
    </div>
    <div class="container header">
      <NuxtLink to="/">
        <LogoHorizontalIcon class="header__logo" />
      </NuxtLink>

      <AppPopover
        v-model="isCatalogMenuVisible"
        placement="bottom-start"
        :distance="32"
      >
        <template #reference>
          <AppButton
            class="header__button d-none d-md-block"
            :icon-start="isCatalogMenuVisible ? CloseIcon : CatalogIcon"
            icon-start-color="white"
            @click="isCatalogMenuVisible = !isCatalogMenuVisible"
          >
            Каталог
          </AppButton>
        </template>
        <template #popover>
          <AppLayoutCatalogMenu />
        </template>
      </AppPopover>

      <AppLayoutSearchBar />

      <ClientOnly>
        <AppButton
          class="header__button header__location d-none d-lg-block"
          color="light"
          fluid
          icon-align="center"
          :icon-start="NearIcon"
          @click="isAddressSelectModalOpened = true"
        >
          {{ addressStore.address ?? 'Укажите ваш адрес' }}
        </AppButton>
      </ClientOnly>

      <AppButton
        class="header__button d-none d-md-block"
        color="light"
        :icon-start="BellIcon"
        @click="isNotificationModalOpened = true"
      />

      <AppPopover
        v-if="isLoggedIn && user"
        v-model="isProfilePopoverVisible"
        placement="bottom-end"
        :distance="36"
      >
        <template #reference>
          <AppAvatar
            class="d-none d-md-block cursor-pointer"
            size="sm"
            :src="user?.avatarUrl"
            @click="isProfilePopoverVisible = true"
          />
        </template>
        <template #popover>
          <AppLayoutProfilePopover class="profile-popover" :user="user" />
        </template>
      </AppPopover>
      <AppButton
        v-else
        border-radius="full"
        color="light"
        :icon-start="UserIcon"
        @click="showAuthModal"
      />

      <NotificationModal v-model="isNotificationModalOpened" />
      <AddressSelectModal v-model="isAddressSelectModalOpened" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  padding: var(--app-spacing-4) 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 12px;

  @include media-breakpoint-down(md) {
    align-items: center;
    padding: var(--app-spacing-2) var(--app-spacing-4);
  }

  &__container {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__button {
    padding: 13px;
    align-items: center;
  }

  &__location {
    white-space: nowrap;
  }

  &__logo {
    width: stretch-size(375, 1920, 70, 115);
    margin-right: var(--app-spacing-6);
  }

  &__address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.profile-popover {
  width: 200px;
}
</style>
