export type NotificationCategory =
  | 'general'
  | 'work'
  | 'master'
  | 'company'
  | 'admin';

export function getNotificationCategoryTitle(category: NotificationCategory) {
  const notificationCategoryTitles: Record<NotificationCategory, string> = {
    general: 'Общие',
    work: 'Рабочие',
    master: 'Мастеру',
    company: 'Компании',
    admin: 'Администраторские',
  };
  return notificationCategoryTitles[category];
}
