<script setup lang="ts">
import { LeftArrowIcon, BellIcon } from '@glow/shared/components/icons';
import { format } from 'date-fns';
import { getNotificationTitle } from '../notifications';
import { getNotificationText } from '../texts';
import { type Notification } from '../types';

const props = defineProps<{
  notification: Notification;
}>();

const goBack = inject<() => void>('goBack');
</script>

<template>
  <AppButton
    variant="link"
    color="secondary"
    icon-start-color="secondary"
    :icon-start="LeftArrowIcon"
    @click="goBack?.()"
  >
    Все уведомления
  </AppButton>
  <div class="notification">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center gap-3">
        <BellIcon class="notification__icon" />
        <div class="notification__category">
          {{ getNotificationTitle(notification.type) }}
        </div>
      </div>
      <div class="notification__date">
        {{ format(props.notification.createdAt, 'dd MMMM yyyy HH:mm') }}
      </div>
    </div>
    <div class="notification__text">
      {{ getNotificationText(props.notification) }}
    </div>
    <AppButton variant="link" fluid @click="goBack?.()">
      Вернуться к уведомлениям
    </AppButton>
  </div>
</template>

<style lang="scss" scoped>
.notification {
  margin-top: 20px;
  padding: 24px;
  border-radius: 16px;
  background: var(--app-color-white-secondary);

  &__icon {
    width: 48px;
    height: 48px;
    color: var(--app-color-primary);
  }

  &__category {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  &__date {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--app-color-black-secondary);
  }

  &__text {
    margin-bottom: 32px;
    font-size: 17px;
    line-height: 22px;
  }
}
</style>
