import type { NotificationType } from './types';

export function getNotificationTitle(type: NotificationType) {
  const notificationTitles: Record<NotificationType, string> = {
    'role-assign': 'Присвоение роли',
    'role-unassign': 'Снятие роли',
    'company-join': 'Вступление в компанию',
    'company-leave': 'Выход из компании',
    'company-invite': 'Приглашение в компанию',
    'company-dismiss': 'Увольнение из компании',
    'appointment-create': 'Новая запись',
    'appointment-cancel': 'Отмена записи',
    'appointment-master-review': 'Оставление отзыва мастеру',
    'appointment-company-review': 'Оставление отзыва компании',
    'company-application-approve': 'Одобрение заявки компании',
    'company-application-decline': 'Отклонение заявки компании',
  };
  return notificationTitles[type];
}
