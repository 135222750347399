<script setup lang="ts">
import NotificationPreview from './NotificationPreview.vue';
import { type AppSelectItem } from '@glow/shared/types/ui/AppSelect';
import { groupBy, map } from 'lodash-es';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { type Notification } from '../types';
import { useReadAllNotifications } from '../api/readAllNotifications';
import { useGetNotificationsInfinite } from '../api/getNotifications';

const emit = defineEmits<{
  (e: 'select-notification', notification: Notification): void;
}>();

const categoryItems: AppSelectItem<string>[] = [
  { value: 'general', title: 'Общие' },
  { value: 'work', title: 'Рабочие' },
  { value: 'master', title: 'Мастеру' },
  { value: 'company', title: 'Компании' },
  { value: 'admin', title: 'Администраторские' },
];
const { data: notificationInfiniteData, isLoading } =
  useGetNotificationsInfinite();
const unreadNotificationsFlat = useFlatInfiniteData(notificationInfiniteData);
const { mutate: readAll } = useReadAllNotifications();

function getNotificationGroupTitle(date: Date) {
  const names = ['Сегодня', 'Вчера', 'Позавчера'];
  const difference = differenceInDays(new Date(), date);
  return difference <= names.length
    ? names[difference]
    : format(date, 'd MMMM');
}
type NotificationGroup = {
  title: string;
  notifications: Notification[];
};
const notificationGroups = computed<NotificationGroup[]>(() =>
  map(
    groupBy(unreadNotificationsFlat.value, (notification) =>
      getNotificationGroupTitle(startOfDay(notification.createdAt))
    ),
    (notifications, title) => ({ title, notifications })
  )
);
function selectNotifiaction(notification: Notification) {
  emit('select-notification', notification);
}
</script>

<template>
  <div>
    <div class="title">
      <p class="h1">Уведомления</p>
      <p class="subtitle">
        Получайте все новости, оповещения и предложения на этой странице
      </p>
    </div>
    <AppSelect
      placeholder="Выберите категорию"
      outlined
      class="mb-3"
      :items="categoryItems"
    />
    <div class="notifications">
      <AppButton
        v-if="notificationGroups.length > 1"
        variant="link"
        color="secondary"
        class="text-end"
        @click="readAll"
      >
        Отметить все, как прочитанное
      </AppButton>
      <div class="notifications__days">
        <div v-for="group in notificationGroups" :key="group.title">
          <div class="notifications__day-text">{{ group.title }}</div>
          <div class="notifications__list">
            <NotificationPreview
              v-for="notification in group.notifications"
              :key="notification.id"
              :notification="notification"
              class="notifications__item"
              @click="selectNotifiaction(notification)"
            />
          </div>
        </div>
      </div>

      <AppLoader v-if="isLoading" />
      <div v-else-if="notificationGroups.length < 1" class="text-center">
        Уведомлений пока нет
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  flex: 1;
  margin-bottom: 30px;

  .subtitle {
    font-size: 17px;
    line-height: 22px;
    color: var(--app-color-black-secondary);
  }
}

.notifications {
  margin-top: 40px;

  &__days {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__day-text {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-transform: uppercase;
    color: var(--app-color-black-secondary);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    cursor: pointer;
  }
}
</style>
